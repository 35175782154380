/**
 * Using a custom _app.js with next-seo you can set default SEO
 * that will apply to every page. Full info on how the default works
 * can be found here: https://github.com/garmeeh/next-seo#default-seo-configuration
 */
import { React, useState, useEffect } from '@biletiniz/ui-core';
import { I18n } from '@biletiniz/intl/react-intl';
import { GlobalStyle, ThemeProvider } from '@biletiniz/ui-style';
import { config } from '@fortawesome/fontawesome-svg-core';
import { intercom } from '@biletiniz/intercom';
import { useGoogleAnalytics } from '@biletiniz/web-app-hooks';
import { ApolloProvider, useApollo } from '@biletiniz/apollo';
import { GoogleAnalyticsScripts } from '@biletiniz/google-analytics';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-day-picker/lib/style.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'rc-checkbox/assets/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'rc-slider/assets/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-popper-tooltip/dist/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-svg-core/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-spinner/react-spinner.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'rc-drawer/assets/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'rc-collapse/assets/index.css';
import '../public/modal-animation.css';

import Head from 'next/head';
import PropTypes from 'prop-types';
import { CookieApprovement } from '@biletiniz/web-app-components';
import { initialSentry } from '../utils/index';

config.autoAddCss = false;

initialSentry();

export default function MyApp({ Component, pageProps }) {
  useGoogleAnalytics();
  const [cookieApproved, setCookieApproved] = useState(true);

  const stopIntercom = pageProps.stopIntercom;
  useEffect(() => {
    if (stopIntercom !== true) {
      intercom();
    }
    if (localStorage.getItem('cookie-approved') === 'true') {
      setCookieApproved(true);
    } else if (pageProps.stopCookie !== true) setCookieApproved(false);
  });
  const apolloClient = useApollo(pageProps);
  return (
    <ApolloProvider client={apolloClient}>
      <I18n lngDict={pageProps.lngDict} locale={pageProps.lng}>
        <Head>
          <title>
            En Ucuz Uçak Bileti Bul | Ucuz otobüs bileti al | Biletiniz.com
          </title>
          <meta
            name="description"
            content="Biletiniz.com da en Ucuz otobüs bileti bul, bilet indirimleri, en iyi otobüs firmaları, ucuz uçak bileti al, yurt içi ve yurt dışı en uygun fiyatlar ile uçak bileti al hemen"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Biletiniz Nereye İsterseniz - Uçak ve otobüs biletinizi uygun fiyatla hemen alın"
          />
          <meta
            property="og:description"
            content="Biletiniz.com da en Ucuz otobüs bileti bul, bilet indirimleri, en iyi otobüs firmaları, uçak bileti sorgulama yap, yurt içi ve yurt dışı en uygun fiyatlar ile uçak bileti al hemen"
          />
          <meta
            name="apple-itunes-app"
            content="app-id=1580286745"
            app-argument="https://apps.apple.com/tr/app/1580286745"
          ></meta>
          <meta
            name="google-play-app"
            content="app-id=com.biletiniz.app"
          ></meta>

          <meta property="og:image" content="/SosialMedia_link.png" />
          <meta property="og:locale" content="tr_TR" />
          <meta property="og:url" content="https://biletiniz.com" />

          <meta
            name="twitter:title"
            content="Biletiniz.com | En Ucuz Uçak Bileti Bul | Ucuz otobüs bileti al"
          />
          <meta
            name="twitter:description"
            content="Biletiniz.com da en Ucuz otobüs bileti bul, bilet indirimleri, en iyi otobüs firmaları, uçak bileti sorgulama yap, yurt içi ve yurt dışı en uygun fiyatlar ile uçak bileti al hemen"
          />
          <meta name="twitter:image" content="/SosialMedia_link.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="image_src" href="/favicon-32x32.png" />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />

          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <GoogleAnalyticsScripts />
        </Head>
        <ThemeProvider>
          <GlobalStyle />
          <main>
            <Component {...pageProps} />
            {!cookieApproved ? (
              <CookieApprovement setCookieApproved={setCookieApproved} />
            ) : null}
          </main>
        </ThemeProvider>
      </I18n>
    </ApolloProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  stopIntercom: PropTypes.bool,
  stopCookie: PropTypes.bool,
};
